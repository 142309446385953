import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
import { getProductById, saveProductReview } from "../../services/Apis";
import { Link, useLocation, useHistory } from "react-router-dom";

import FeaturedProducts from "../../components/featured-products/index";
// import RelatedProducts from '../../components/related-products/index'
import SignupModal from "../../components/signup-modal/SignupModal";
import YoutubeModal from "../../components/YoutubeModal/YoutubeModal";

// import banner4 from "../../assets/img/banners/banner4.jpeg";
// import mainImage from '../../assets/img/product/product-details1.jpg'
// import noImage from "../../assets/img/product/project.jpeg";
import noImage from "../../assets/img/product/noimage.jpeg";
import { FaYoutube } from "react-icons/fa";

import MetaData from "../../components/MetaData";
import NotFound from "../not-found";
import PreLoader from "../../components/preloader";

function ReviewStar({ starNumber }) {
  let stars = [];
  for (let i = 0; i < starNumber; i++) {
    stars.push(
      <li>
        <i className="fa fa-star" aria-hidden="true"></i>
      </li>
    );
  }

  return stars;
}
function Product() {
  const history = useHistory();
  const location = useLocation();
  let search = useLocation().search;
  let pid = new URLSearchParams(search).get("p");
  const [product, setProduct] = useState([]);
  const [ratingStars, setRatingStars] = useState([]);
  const [currentSelectedPrice, setCurrentSelectedPrice] = useState("");
  const [currentSelectedSalePrice, setCurrentSelectedSalePrice] = useState("");
  const [currentSelectedLicense, setCurrentSelectedLicense] = useState("");
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openYTModal, setOpenYTModal] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  // const [relatedProducts, setRelatedProducts] = useState([])

  useEffect(() => {
    getProductDetail(pid);
  }, [location]);

  const IsMobileDevice = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const onChangeLicensePrice = (event, product) => {
    // console.error(event.target.value, product);
    if (event.target.value && event.target.value != null) {
      setCurrentSelectedLicense(event.target.value);
      if (event.target.value == 2) {
        setCurrentSelectedPrice(product.multi_price);
        if (
          product.multi_sale_price &&
          product.multi_sale_price != "" &&
          product.multi_sale_price != null
        ) {
          setCurrentSelectedSalePrice(product.multi_sale_price);
        }
      } else {
        setCurrentSelectedPrice(product.price);
        if (
          product.sale_price &&
          product.sale_price != "" &&
          product.sale_price != null
        ) {
          setCurrentSelectedSalePrice(product.sale_price);
        }
      }
    }
  };

  const getProductDetail = (pid) => {
    // let pid = urlParams.productId
    getProductById(pid).then((result) => {
      let urlArr = window.location.href.split("/");
      let productSeoUrl = urlArr[urlArr.length - 1].split("?")[0];
      setLoadingProducts(false);
      // console.error('PRODUCT  == > ', product);
      if (result && result.code === 200 && result.data && result.data[0]) {
        setProduct(result.data[0]);
        if (
          result.data[0].name !== "" &&
          productSeoUrl &&
          result.data[0].seo_url &&
          productSeoUrl !== result.data[0].seo_url
        ) {
          setShowNotFound(true);
        } else {
          setShowNotFound(false);
        }
        if (search.indexOf("review") >= 0) {
          document.getElementById("send-review").scrollIntoView({
            behavior: "instant",
            block: "start",
            inline: "nearest",
          });
          setTimeout(function () {
            window.addEventListener("load", () => {
              if (document.getElementById("li-review")) {
                document.getElementById("li-review").classList.add("active");
              }
              if (document.getElementById("li-description")) {
                document
                  .getElementById("li-description")
                  .classList.remove("active");
              }
              if (document.getElementById("li-faqs")) {
                document.getElementById("li-faqs").classList.remove("active");
              }
            });
          }, 1000);
        } else {
          document.getElementById("root").scrollIntoView({
            behavior: "instant",
            block: "start",
            inline: "nearest",
          });
          setTimeout(function () {
            window.addEventListener("load", () => {
              if (document.getElementById("li-description")) {
                document.getElementById("li-review").classList.add("active");
              }
              if (document.getElementById("li-review")) {
                document
                  .getElementById("li-description")
                  .classList.remove("active");
              }
              if (document.getElementById("li-faqs")) {
                document.getElementById("li-faqs").classList.remove("active");
              }
            });
          }, 1000);
        }
        if (result.data[0].rating > 0) {
          let ratingStars = [];
          for (var i = 0; i < result.data[0].rating; i++) {
            ratingStars.push(
              <li>
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
            );
          }
          setRatingStars(ratingStars);
        }
        setCurrentSelectedPrice(result.data[0].price);
        if (
          result.data[0].sale_price &&
          result.data[0].sale_price !== "" &&
          result.data[0].sale_price !== null
        ) {
          setCurrentSelectedSalePrice(result.data[0].sale_price);
        }
        setCurrentSelectedLicense(1);
        // console.error('RESULT => ', product)
      } else {
        setShowNotFound(true);
      }
    });
  };

  const resetReview = (event) => {
    event.preventDefault();
    const elem = event.target.elements;
    elem.name.value = elem.comments.value = "";
  };

  const saveReview = (event) => {
    event.preventDefault();
    const elem = event.target.elements;

    // console.error(elem.rating.value);

    if (
      elem.name.value == "" ||
      elem.comments.value == "" ||
      elem.rating.value == ""
    ) {
      setFormErrorMsg("Please provide required fields");
      return;
    }

    let data = {
      product: pid,
      author: elem.name.value,
      text: elem.comments.value,
      rating: elem.rating.value,
      status: false,
    };
    saveProductReview(data).then((res) => {
      console.error("RESPONSE =>", res); //return;
      if (res && res.code == 200 && res.success) {
        // history.push("/")
        elem.name.value = elem.comments.value = elem.rating.value = "";
        setFormErrorMsg(res.message);
        setTimeout(function () {
          setFormErrorMsg("");
        }, 2500);
      } else {
        console.error(res.message);
      }
    });
  };

  const checkUserStatus = () => {
    if (
      localStorage.access_token !== undefined &&
      localStorage.access_token !== ""
    ) {
      //Logged In.
      history.push(
        "/checkout/" + product.product_id + "/" + currentSelectedLicense
      );
    } else {
      //open signup here
      localStorage.signupFromAddToCart = true;
      localStorage.signupFromProduct = pid + "_" + currentSelectedLicense;
      setOpenModal(!openModal);
    }
  };
  const viewYoutubeVideo = () => {
    setOpenYTModal(!openYTModal);
  };

  const closeModal = () => {
    setOpenModal(false);
    localStorage.signupFromAddToCart = false;
    localStorage.signupFromProduct = "";
  };
  const closeYTModal = () => {
    setOpenYTModal(false);
  };

  const moveToReviews = () => {
    document.getElementById("btn-add-cart").scrollIntoView({
      behavior: "instant",
      block: "start",
      inline: "nearest",
    });

    if (document.getElementById("li-review")) {
      document.getElementById("li-review").classList.add("active");
    }
    if (document.getElementById("li-description")) {
      document.getElementById("li-description").classList.remove("active");
    }
    if (document.getElementById("li-faqs")) {
      document.getElementById("li-faqs").classList.remove("active");
    }
  };

  const stripImageAlt = (imgName) => {
    try {
      if (imgName && imgName !== "") {
        return imgName
          .replace("catalog/", "")
          .replaceAll("-", " ")
          .split(".")[0];
      }
      return "";
    } catch (err) {
      return "";
    }
  };

  return (
    <>
      <SignupModal openModal={openModal} closeModal={closeModal} />
      <YoutubeModal
        videoUrl={product.youtube_url}
        openModal={openYTModal}
        closeModal={closeYTModal}
      />
      {product && !showNotFound && <MetaData data={product} />}

      {!loadingProducts && !showNotFound && product && (
        <div
          id="inner-page-banner-area"
          className="inner-page-banner-area"
          style={{ minHeight: "165px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb-area custom-banner-area">
                  <h2>{product && product.name ? product.name : ""}</h2>
                  <div className="display-flex">
                    {ratingStars && (
                      <ul className="ml10 mt10">{ratingStars}</ul>
                    )}
                    {product &&
                      product.product_review &&
                      product.product_review.length > 0 && (
                        <a
                          className="ml10 mt10 blue"
                          href="#review"
                          onClick={moveToReviews}
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          ( {product.product_review.length} reviews )
                        </a>
                      )}
                  </div>

                  {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loadingProducts && (
        <div className="product-area padding-top-0-after-desk">
          <div className="container" id="home-isotope">
            <div className="row featuredContainer text-center">
              <PreLoader />
            </div>
          </div>
        </div>
      )}

      <p
        dangerouslySetInnerHTML={{
          __html: product.meta_keyword,
        }}
      />

      {!loadingProducts && !showNotFound && product && (
        <div className="product-details2-area">
          <div className="container">
            <div className="row">
              <div className="col-md-9 inner-product-details-right display-flex">
                <ul style={{ margin: "0px !important" }}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {product &&
                    product.category &&
                    product.category.seo_url !== "" && (
                      <>
                        <li className="ml5 mr5"> / </li>
                        <li>
                          <Link
                            to={
                              "/" +
                              product.category.seo_url +
                              "?c=" +
                              product.category.category_id
                            }
                          >
                            {product.category.name}
                          </Link>
                        </li>
                      </>
                    )}
                  {product && product.name !== "" && (
                    <>
                      <li className="ml5 mr5"> / </li>
                      <li>
                        <a href="">{product.name}</a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div
                className="col-md-3 display-flex"
                style={{ textAlign: "right" }}
              ></div>
            </div>
            {IsMobileDevice() && (
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  {product.short_description &&
                  product.short_description !== "" ? (
                    <p>{product.short_description}</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="inner-product-details-left">
                  <div className="tab-content">
                    <div className="tab-pane fade active in" id="related__0">
                      <a href="#" className="zoom ex1">
                        <img
                          alt={stripImageAlt(product.image)}
                          src={
                            product.image && product.image !== ""
                              ? "https://www.ezowotemplate.com/adminpanel/image/" +
                                product.image
                              : noImage
                          }
                          className="img-responsive"
                        />
                      </a>
                    </div>

                    {product.product_images &&
                      product.product_images.map((prodImage, index) => {
                        return (
                          <div className="tab-pane fade" id={"related" + index}>
                            <a href="#" className="zoom ex1">
                              <img
                                alt={stripImageAlt(prodImage.image)}
                                src={
                                  "https://www.ezowotemplate.com/adminpanel/image/" +
                                  prodImage.image
                                }
                                className="img-responsive"
                              />
                            </a>
                          </div>
                        );
                      })}
                  </div>
                  <ul>
                    <li className="active">
                      <a
                        href="#related__0"
                        data-toggle="tab"
                        aria-expanded="false"
                      >
                        <img
                          alt={stripImageAlt(product.image)}
                          src={
                            product.image && product.image != ""
                              ? "https://www.ezowotemplate.com/adminpanel/image/" +
                                product.image
                              : noImage
                          }
                          className="img-responsive"
                        />
                      </a>
                    </li>

                    {product.product_images &&
                      product.product_images.map((prodImage, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={"#related" + index}
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              <img
                                alt={stripImageAlt(prodImage.image)}
                                src={
                                  "https://www.ezowotemplate.com/adminpanel/image/" +
                                  prodImage.image
                                }
                                className="img-responsive"
                              />
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="inner-product-details-right">
                  {!IsMobileDevice() &&
                  product.short_description &&
                  product.short_description !== "" ? (
                    <p>{product.short_description}</p>
                  ) : (
                    <></>
                  )}

                  <form id="checkout-form" className="license-panel">
                    <h4>Choose your License</h4>
                    <ul className="more-option">
                      <li>
                        <div className="form-group">
                          <div className="custom-select">
                            <select
                              id="size"
                              className="select2 custom-dropdown"
                              onChange={(e) => onChangeLicensePrice(e, product)}
                            >
                              <option key="1" selected value="1">
                                Single user License
                              </option>
                              {product.multi_price && product.multi_price > 0 && (
                                <option key="2" value="2">
                                  Multiple user License
                                </option>
                              )}
                            </select>
                            <p className="price display-inline ml20">
                              {currentSelectedSalePrice &&
                              currentSelectedSalePrice !== "" ? (
                                <>
                                  <span className="product-price">
                                    ${currentSelectedPrice}
                                  </span>{" "}
                                  {"$" + currentSelectedSalePrice}
                                </>
                              ) : (
                                currentSelectedPrice
                              )}
                            </p>
                          </div>
                          {product && product.license_text !== null && (
                            <p
                              className="little-text"
                              dangerouslySetInnerHTML={{
                                __html: product.license_text,
                              }}
                            />
                          )}
                        </div>
                      </li>
                      <li>
                        {product &&
                        product.document_url &&
                        product.document_url != "" ? (
                          <button
                            className="btn-add-cart"
                            id="btn-add-cart"
                            type="button"
                            onClick={checkUserStatus}
                            value="Add to Cart"
                          >
                            Add to Cart
                          </button>
                        ) : (
                          <button
                            className="btn-add-cart"
                            id="btn-add-cart"
                            type="button"
                            value="Not Available"
                          >
                            Not Available
                          </button>
                        )}
                      </li>
                    </ul>
                    {product &&
                      product.youtube_url &&
                      product.youtube_url != "" && (
                        <ul className="inner-product-details-cart mb-0">
                          <li>
                            <h4 className="watch-video">
                              Watch YouTube Video{" "}
                            </h4>
                          </li>
                          <li>
                            <div class="youtube-wrapper">
                              <FaYoutube
                                onClick={viewYoutubeVideo}
                                className="youtube-icon"
                              />
                            </div>
                            <img
                              style={{ width: "174px", height: "89px" }}
                              alt="Youtube video"
                              src={
                                "http://img.youtube.com/vi/" +
                                product.youtube_url +
                                "/maxresdefault.jpg"
                              }
                            />
                          </li>
                        </ul>
                      )}
                  </form>
                </div>
              </div>
            </div>

            {product && (
              <div
                className="product-details-tab-area"
                id="product-details-tab-area"
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <ul>
                      {product &&
                        product.description &&
                        product.description !== "" && (
                          <li id="li-description" className="active">
                            <a
                              href="#description"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              Description
                            </a>
                          </li>
                        )}
                      {product.product_review &&
                        product.product_review.length > 0 && (
                          <li id="li-review">
                            <a
                              href="#review"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              Review{" "}
                              {product.product_review
                                ? "(" + product.product_review.length + ")"
                                : ""}
                            </a>
                          </li>
                        )}
                      {/* {product.productfaq && product.productfaq.length > 0 && (
                      <li id="li-faqs">
                        <a
                          href="#add-faqs"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          FAQS{" "}
                          {product.productfaq
                            ? "(" + product.productfaq.length + ")"
                            : ""}
                        </a>
                      </li>
                    )} */}
                    </ul>
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    id="tab-panels"
                  >
                    <div className="tab-content">
                      <div className="tab-pane fade active in" id="description">
                        {product &&
                        product.description &&
                        product.description !== "" ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="tab-pane fade" id="review">
                        {/* <p>Reviews</p> */}
                        {product.product_review &&
                          product.product_review.map((review, index) => {
                            return (
                              <div className="review-panel">
                                <p className="mb-0">
                                  <span className="bold mr10">
                                    {review.author}
                                  </span>{" "}
                                  {new Date(
                                    review.date_added
                                  ).toLocaleDateString("en-US", {
                                    weekday: undefined,
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                  {review.rating > 0 && (
                                    <ul className="product-reviews">
                                      <ReviewStar starNumber={review.rating} />
                                    </ul>
                                  )}
                                </p>
                                <p className="review-text mb-0">
                                  {review.text}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                      <div className="tab-pane fade" id="add-faqs">
                        {product.productfaq &&
                          product.productfaq.map((faq, index) => {
                            return (
                              <div className="review-panel">
                                <h4 className="mb-0">{faq.question}</h4>
                                <p>{faq.answer}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {product &&
              (product.offer_heading !== "" ||
                product.offer_sub_heading !== "" ||
                product.offer_sub_heading2 !== "" ||
                product.banner_url !== null) && (
                <div className="offer-area1 hidden-after-desk">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="brand-area-box-l">
                          {product.offer_heading && (
                            <span>{product.offer_heading}</span>
                          )}
                          {product.offer_sub_heading && (
                            <p className="post-ad-heading">
                              {product.offer_sub_heading}
                            </p>
                          )}
                          {product.offer_sub_heading2 && (
                            <p>{product.offer_sub_heading2}</p>
                          )}
                          {product.offer_button_text !== null &&
                            product.offer_button_url !== null && (
                              <a
                                href={product.offer_button_url}
                                target="_blank"
                                className="btn-shop-now-fill"
                              >
                                {product.offer_button_text}
                              </a>
                            )}
                        </div>
                      </div>
                      <div className="countdown"></div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="brand-area-box-r">
                          {product.banner_url && product.banner_url !== null && (
                            <a href="#">
                              <img
                                style={{ width: "460px", height: "433px" }}
                                src={
                                  "https://www.ezowotemplate.com/adminpanel/image/catalog/banners/" +
                                  product.banner_url
                                }
                                alt={product.offer_heading}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div
              id="send-review"
              className="single-blog-details-leave-comments  mt50 mb50"
            >
              <h3 className="display-inline">Send us your Review</h3>
              {formErrorMsg && formErrorMsg !== "" && (
                <span className="invalid-text ml10">{formErrorMsg}</span>
              )}
              <div className="row mt10">
                <div className="contact-form">
                  <form id="frmReview" onSubmit={(e) => saveReview(e)}>
                    <fieldset>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Name *"
                            className="form-control"
                            id="name"
                            name="name"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <select
                            id="rating"
                            name="rating"
                            className="form-control"
                          >
                            <option key="0" value="">
                              {" "}
                              - Select your Rating -{" "}
                            </option>
                            <option key="1" value="1">
                              1
                            </option>
                            <option key="2" value="2">
                              2
                            </option>
                            <option key="3" value="3">
                              3
                            </option>
                            <option key="4" value="4">
                              4
                            </option>
                            <option key="5" value="5">
                              5
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <textarea
                            placeholder="Comments *"
                            className="textarea form-control"
                            id="comments"
                            name="comments"
                            rows="8"
                            cols="20"
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            name="save_account_details"
                            className="btn-send-message"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-response"></div>
                </div>
              </div>
            </div>

            <FeaturedProducts />
          </div>
        </div>
      )}

      {!loadingProducts && showNotFound && <NotFound />}
    </>
  );
}
export default Product;
