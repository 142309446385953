import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import SignupModal from "../../components/signup-modal/SignupModal";
// import imgPayment1 from '../../assets/img/payment-method1.jpg'
// import imgPayment2 from '../../assets/img/payment-method2.jpg'
// import imgPayment3 from '../../assets/img/payment-method3.jpg'
// import imgPayment4 from '../../assets/img/payment-method4.jpg'
import imgTrustPilot from '../../assets/img/trust-pilot.png';

import AllPagesLinks from "../../components/all-pages-links/index";

function Footer(props) {
  const location = useLocation();
  const [notHomePage, SetNotHomePage] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  // const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    // history.push('/')
    window.location = "";
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    SetNotHomePage(
      window &&
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/"
    );
  }, [location]);

  return (
    <footer>
      <SignupModal openModal={openModal} closeModal={closeModal} />
      <div className={!notHomePage ? 'footer-area footer-area-catgegory' : 'footer-area'}>
        <div className="footer-area-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-box">
                  <h3>Information</h3>
                  <ul className="info-list">
                    <AllPagesLinks allPages={props.allPages} />
                    {localStorage.access_token !== undefined &&
                      localStorage.access_token !== "" && (
                        <li>
                          <NavLink
                            exact={true}
                            activeClassName="active"
                            to="/my-account/dashboard"
                          >
                            My Account
                          </NavLink>
                        </li>
                      )}
                    <li>
                      {(localStorage.access_token === undefined ||
                        localStorage.access_token === "") && (
                        <NavLink
                          exact={true}
                          activeClassName="active"
                          to="/login"
                        >
                          Login
                        </NavLink>
                      )}
                      {localStorage.access_token !== undefined &&
                        localStorage.access_token !== "" && (
                          // <a href="#" onClick={() => handleLogout()}>Logout</a>
                          <Link onClick={() => handleLogout()}>Logout</Link>
                        )}
                    </li>
                    {(localStorage.access_token === undefined ||
                      localStorage.access_token === "") && (
                      <li>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenModal(!openModal);
                          }}
                        >
                          Signup
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <div className="footer-box">
                  <h3>Popular Categories</h3>
                  <ul className="tag-list">
                    {props.allCategories.map((cat, index) => {
                      return (
                        <li key={index}>
                          <NavLink
                            exact={true}
                            to={
                              "/" +
                              (cat.seo_url && cat.seo_url !== ""
                                ? cat.seo_url
                                : cat.name.replace(" ", "-")) +
                              "?c=" +
                              cat.category_id
                            }
                          >
                            {cat.name}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="footer-box">
                  <h3>Stay With Us</h3>
                  <p>
                    Connect with us through our social media network for updates and to avail discounts and special offers
                  </p>
                  <ul className="footer-social">
                    <li>
                      <a href="https://web.facebook.com/EzowoTemplate/" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://twitter.com/ranaumarrashid" target="_blank">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/company/ezowotemplate/" target="_blank">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/ezowotemplates/" target="_blank">
                        <i className="fa fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCCASK06GSbvIcADutOsR0kg" target="_blank">
                        <i className="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li> */}
                  </ul>
                  <p>
                    <a href="https://www.trustpilot.com/review/ezowotemplate.com" target="_blank"><img src={imgTrustPilot} alt="Trust pilot" /></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <p>© 2022 Ezowo All Rights Reserved.</p>
              </div>
              {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <ul className="payment-method">
                      <li>
                          <a href="#"><img src={imgPayment1} alt="payment-method" /></a>
                      </li>
                      <li>
                          <a href="#"><img src={imgPayment2} alt="payment-method" /></a>
                      </li>
                      <li>
                          <a href="#"><img src={imgPayment3} alt="payment-method" /></a>
                      </li>
                      <li>
                          <a href="#"><img src={imgPayment4} alt="payment-method" /></a>
                      </li>
                  </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
